import React from "react";
import { Container, Secondary, Primary } from "../styles/navActions";
import { Link } from "../styles/navItems";

const NavActions = () => {
  return (
    <Container>
      <Link href="https://app.buildupward.com/login">
        <Secondary>Log In</Secondary>
      </Link>

      <Link href="https://app.buildupward.com/signup">
        <Primary>Get started for free</Primary>
      </Link>
    </Container>
  );
};

export default NavActions;
