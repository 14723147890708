import React, { useState } from "react";
import {
  Container,
  Headline,
  Heading,
  SectionHeader,
} from "../styles/apiStepByStep.js";
import CodeBlock from "../components/syntaxHighlighter.js";
import { apiByLanguage } from "../utils/codeByLanguage";
import InlineCodeBlock from "./inlineCodeBlock.js";

const APIStepByStep = () => {
  const [language, setLanguage] = useState("cURL");

  const handleSetLanguage = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <Container>
      <SectionHeader>Overview</SectionHeader>
      <Headline>
        In this guide you will learn how to use the Upward API to upload files,
        extract data, and manage your data requests. By the end you will know
        how to get started with the Upward API and integrate it into your
        applications and workflows.
      </Headline>
      <SectionHeader>Uploading files</SectionHeader>
      <Heading>How would you like to upload your files?</Heading>
      <Headline>
        You have two options for uploading files to our platform. You can either
        upload files directly through our API, providing full control over the
        process, or generate a <a href="#no-bills">drag-and-drop form</a> that
        can be shared with third parties, allowing them to upload the necessary
        files on your behalf. Choose the method that best suits your workflow
        and needs.
      </Headline>
      <Heading>Generate URLs for the files being uploaded</Heading>
      <Headline>
        To make our system scalable to allow for a high volume of bill
        processing, we pre generate urls to upload your files to Google Cloud
        Services. We are going to use the{" "}
        <InlineCodeBlock
          verb={"POST"}
          endpoint={"/api/generateUploadUrls"}
          backgroundColor={"#FC944A"}
        />{" "}
        endpoint to generate these urls to prepare our uploads.
      </Headline>
      <Headline>
        Let's pretend we have a customer called Arvind and he has the following
        file to upload: {`C&I_Electric.pdf`}. To upload his file we are going to
        make the following HTTP request:
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGenerateExtractURLs}
        type={"code"}
        language={language}
        setLanguage={handleSetLanguage}
        styleType={"guide"}
      />
      <Headline>
        The following is the response you get from calling the endpoint above.
        Notice the signed urls in the response. You will use this url in the
        call described below to upload your file to GCS. The requestId in the
        response will be used later on when you need to access your uploaded
        bills and analytics on those bills. Also keep in mind the unsupported
        bills key in the response. These would be files not supported by Upward
        based on the file extension. Upward supports PDFs and standard image
        types.
      </Headline>
      <CodeBlock
        type={"response"}
        code={apiByLanguage.arvindGenerateExtractURLs.response}
        styleType={"guide"}
      />
      <Heading>Upload files to Google Cloud Storage</Heading>
      <Headline>
        The response will include a signedUrls object, where each file name is a
        key and its corresponding Google Cloud Storage (GCS) signed URL is the
        value. You will need to make an HTTP PUT call for each file with the URL
        that matches its name. See the example below to understand how to make
        the call to the endpoint. Notice the url in the PUT endpoint below is
        the same as the url in the response of the previous POST call.
      </Headline>
      <Headline>
        Note: Signed urls expire after 15 minutes. Only generate the signed urls
        when the files are ready to be uploaded.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGCSUpload}
        language={language}
        setLanguage={handleSetLanguage}
        type={"code"}
        styleType={"guide"}
      />
      <Headline>
        There is no response object from this PUT call, you should just see a
        status of 200 if the call was successfully run.
      </Headline>
      <Heading id="no-bills">Drag-and-drop form</Heading>
      <Headline>
        This section explains how to create a form that you can share with your
        customers, allowing them to upload their bills directly into our system
        for your access whenever needed.
      </Headline>
      <Headline>
        Our system allows you to create a form which you can send to your
        customers where they can upload their bills. You will be notified by a
        webhook when the data is ready to be retrieved.
      </Headline>
      <Heading>Create a form to send to your customers</Heading>
      <Headline>
        The endpoint{" "}
        <InlineCodeBlock
          verb={"POST"}
          endpoint={"/api/createForm"}
          backgroundColor={"#FC944A"}
        />
        {""} is used to create a form which will provide a url in the response
        which you can send out to whoever you'd like so that they can upload
        their bills asynchronously.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindCreateForm}
        type={"code"}
        language={language}
        setLanguage={handleSetLanguage}
        styleType={"guide"}
      />
      <Headline>
        Below is the response from the createForm API call. It includes the url
        that you can send to your customers or anyone who will be uploading
        bills to your account.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindCreateForm.response}
        type={"response"}
        styleType={"guide"}
      />
      <SectionHeader>Extracting data</SectionHeader>
      <Heading>Get All Requests</Heading>
      <Headline>
        Now that the file or files have been uploaded to GCS or you have used
        the create form link to get your customers to upload their bills our
        system starts to process them. At first they will be pending requests
        and once they have been processed they move to approved requests when
        completed. Now we will make an API call that allows us to see all of the
        requests that have been made and their respective statuses.
      </Headline>

      <CodeBlock
        code={apiByLanguage.arvindGetAllRequests}
        type={"code"}
        language={language}
        setLanguage={handleSetLanguage}
        styleType={"guide"}
      />
      <Headline>
        You'll see in the response below that there are requests in the states
        declined, approved, and pending. This is an exhaustive list of all the
        requests made on the current account.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGetAllRequests.response}
        type={"response"}
        styleType={"guide"}
      />
      <Heading>Get a specific request</Heading>
      <Headline>
        Once a request has been approved, you can pull all the data and
        analytics that were extracted from the bills in that request. Look at
        the API call below to see how to get your data for a specific request by
        using the requestId you got when you first generated the signed urls or
        received in the webhook response if the drag-and-drop form was used.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGetRequestData}
        language={language}
        setLanguage={handleSetLanguage}
        type={"code"}
        styleType={"guide"}
      />
      <Headline>
        This API call gives all the data for the requestId provided. you can
        chain these API calls together to get the data for multiple requests
        that have been approved. Below is the response from the API call to get
        the data of the request using the specified requestId.
      </Headline>
      <CodeBlock
        code={apiByLanguage.arvindGetRequestData.response}
        type={"response"}
        styleType={"guide"}
      />
      <Headline>
        At this point you have been introduced to the Upward API. Go ahead and
        keeping making calls to our API for all your utility bill needs. If you
        have any questions please don't hesistate to reach out to us at
        support@buildupward.com.
      </Headline>
    </Container>
  );
};

export default APIStepByStep;
