import styled from "styled-components";

export const Container = styled.div`
  margin-top: 200px;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const Header = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto 30px auto;
  width: max-content;
`;

export const CompareContainer = styled.div`
  display: flex;
`;

export const UpwardCard = styled.div`
  box-shadow: 0px 0px 15px 10px rgba(57, 88, 255, 0.1);
  background-color: #ffffff;
  padding: 25px;
  max-width: 400px;
  border-radius: 30px;
  flex-grow: 1;
`;

export const Logo = styled.img`
  height: 40px;
  margin: 0 auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Feature = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Check = styled.img``;

export const FeatureText = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const OthersContainer = styled.div`
  flex-grow: 2;
`;

export const OthersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const OthersHeaderTextContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OthersHeaderText = styled.div`
  width: 158px;
  font-size: 20px;
  text-align: center;
`;

export const HowWeCompareImageContainer = styled.img`
  //   padding: 0 200px;
  margin-top: 100px;
  //   width: calc(100vw - 200px);
  height: 700px;
  width: 100%;
`;
