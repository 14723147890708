import styled from "styled-components";
import { blue } from "../utils/colors.js";
import "@fontsource/plus-jakarta-sans";

export const ContainerPricing = styled.div`
  padding: 48px;
  font-family: "Plus Jakarta Sans";
  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
`;

export const PageContainer = styled.div`
  width: 100vw;
  min-width: 974px;
  //   background-color: #ffffff;
  position: relative;
`;

export const Header = styled.div`
  font-size: 55px;
  font-weight: 700;
  width: 600px;
  padding: 20px 48px;
  margin-bottom: 10px;
  line-height: 75px;
  @media only screen and (max-width: 430px) {
    font-size: 26px;
    line-height: 39px;
    padding: 20px;
    width: 340px;
  }
`;

export const SubHeader = styled.div`
  padding: 20px 48px;
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 20px;
  @media only screen and (max-width: 430px) {
    font-size: 14px;
    padding: 20px;
    margin-bottom: 30px;
  }
`;

export const PlansCard = styled.div`
  border-radius: 10px;
  background-color: #e7f1ff;
  padding: 42px;
  //   width: 100%;
  height: max-content;
  // min-width: 1250px;
  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
`;

export const ActionsAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 62px;
  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

export const TextCTAContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

export const GreyText = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #b5b5b5;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const TextWithCTA = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CTA = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${blue};
  text-decoration: underline;
  cursor: pointer;
`;

export const PlansContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 1180px;
  @media only screen and (max-width: 430px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 574px;
  width: 260px;
`;

export const PlanHeader = styled.div`
  font-weight: 800;
  font-size: 30px;
`;

export const PlanPrice = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 32px;
`;

export const PlanPriceNumber = styled.div`
  font-size: 66px;
  font-weight: 800;
  color: ${(props) => props.color};
`;

export const PlanPriceSign = styled.div`
  font-size: 40px;
  color: ${(props) => props.color};
  font-weight: 800;
  position: relative;
  top: 10px;
`;

export const PlanPriceUnit = styled.div`
  color: #b5b5b5;
  font-size: 16px;
  font-weight: 600;
`;

export const PlanDescription = styled.div`
  font-weight: 600;
  font-size: 16px;
  max-width: 206px;
  margin-top: 32px;
  // height: ${(props) => props.height};
`;

export const PlanLineItemsContainer = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 50px;
`;

export const PlanLineItem = styled.div`
  font-weight: 600;
  font-size: 16px;
  width: 206px;
`;

export const PlanButton = styled.div`
  background-color: ${(props) => props.color};
  color: #ffffff;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 10px 40px;
  width: max-content;
  margin-top: 32px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

export const OtherSolutionsCard = styled.div`
  border-radius: 10px;
  background-color: #00093d;
  padding: 42px;
  //   width: 100%;
  height: max-content;
  margin-top: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

export const OtherSolutionsText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  @media only screen and (max-width: 430px) {
    gap: 20px;
  }
`;

export const OtherSolutionsHeader = styled.div`
  font-size: 40px;
  font-weight: 800;
  color: #ffffff;
  @media only screen and (max-width: 430px) {
    font-size: 20px;
    width: 100%;
  }
`;

export const OtherSolutionsSubtext = styled.div`
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  max-width: 744px;
`;

export const OtherSolutionsButton = styled.div`
  border-radius: 100px;
  border: 2px solid #ffffff;
  background-color: #00093d;
  color: #ffffff;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  box-sizing: border-box;
  width: max-content;
  line-height: 20px;
  height: max-content;
  cursor: pointer;
  @media only screen and (max-width: 430px) {
    margin-top: 20px;
    font-size: 20px;
    padding: 10px 50px;
  }
`;

export const FeaturesCard = styled.div`
  border-radius: 10px;
  background-color: #eff2ff;
  padding: 42px;
  //   width: 100%;
  height: max-content;
  margin-top: 44px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
`;

export const FeaturesHeader = styled.div`
  margin: 0 auto;
  font-size: 30px;
  font-weight: 500;
  width: max-content;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

export const Feature = styled.div`
  display: flex;
  gap: 10px;
`;

export const FeatureText = styled.div`
  font-size: 20px;
  font-weight: 500;
  max-width: 300px;
`;

export const FeatureIcon = styled.img`
  height: 20px;
  width: 20px;
  position: relative;
`;

export const PlanPricePerBill = styled.div`
  color: ${(props) => props.color};
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
`;

export const SpecialContainer = styled.div`
  // margin-top: 20px;
  // @media only screen and (max-width: 1470px) {
  //   margin-top: 20px;
  // }
`;

export const EmptySpace = styled.div`
  height: 16px;
  margin-top: 36px;
  width: 100%;
  @media only screen and (max-width: 1470px) {
    margin-top: 60px;
  }
`;
