import styled from "styled-components";
import { blue } from "../../utils/colors";

export const Container = styled.div`
  width: 100%;
  @media only screen and (min-width: 950px) {
    display: none;
  }
`;

export const Icon = styled.div`
  color: ${blue};
  font-size: 12px;
`;
