import styled from "styled-components";

export const Container = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: max-content;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 800;
  color: #ffffff;
  background-color: ${(props) => props.backgroundColor};
`;
