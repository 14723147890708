import React, { useState } from "react";
import {
  Container,
  SectionContainer,
  SectionHeader,
  FlexRow,
  Column,
  SubSectionHeader,
  Text,
  H3,
  ParameterContainer,
  ParameterDescription,
  ParameterHeader,
  ParameterType,
  ParameterValue,
  ParameterRequired,
  Parameters,
  ReturnsText,
} from "../styles/apiOverview.js";
import CodeBlock from "./syntaxHighlighter.js";
import { apiByLanguage } from "../utils/codeByLanguage";

const APIOverview = () => {
  const [language, setLanguage] = useState("cURL");
  const handleSetLanguage = (e) => {
    setLanguage(e.target.value);
  };
  return (
    <Container>
      <SectionContainer>
        <SectionHeader>API Reference</SectionHeader>
        <FlexRow>
          <Column>
            <Text>
              The Upward API is organized around REST. Our API has predictable
              resource-oriented URLs, accepts form-encoded request bodies,
              returns JSON-encoded responses, and uses standard HTTP response
              codes, authentication, and verbs.
            </Text>
          </Column>
          <Column>
            <SubSectionHeader>Want a closer look?</SubSectionHeader>
            <div style={{ marginBottom: "15px" }}>
              <Text>
                Checkout our detailed docs{" "}
                <a href="https://app.buildupward.com/apidocumentation#tag/Extract-Data/operation/generateUploadUrls">
                  here.
                </a>
              </Text>
            </div>

            <SubSectionHeader>Not a developer?</SubSectionHeader>
            <Text>
              Sign up for our platform and get all the same features supported
              in our API with our easy to use application.
            </Text>
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Generate Upload URLs</SectionHeader>
        <FlexRow>
          <Column>
            <Text>
              The /generateUploadUrls route is a POST endpoint designed to
              facilitate the data extraction process from energy bills by
              generating signed URLs for uploading files directly to Google
              Cloud Storage (GCS). When invoked, this route accepts a JSON
              payload containing an array of filenames, a customer field (a
              string identifying the customer), and a boolean field named
              “detailed” to specify whether the data extraction should be simple
              or detailed. Upon processing the request, the route returns a
              unique requestId and an object containing key-value pairs, where
              each filename is mapped to its corresponding signed upload URL.
              Urls expire after 15 minutes, and requests with no files uploaded
              after 15 minutes are deleted. This allows clients to securely
              upload energy bill files to GCS, initiating the data extraction
              process based on the provided parameters. This endpoint accepts
              common image and pdf filetypes including .png, .jpg, .jpeg, .tiff,
              and .pdf.
            </Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>filenames</ParameterValue>
                  <ParameterType>array</ParameterType>
                  <ParameterRequired>Required</ParameterRequired>
                </ParameterHeader>
                <ParameterDescription>
                  An array of file names, consisting of each filename that will
                  be uploaded.
                </ParameterDescription>
              </ParameterContainer>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>customer</ParameterValue>
                  <ParameterType>string</ParameterType>
                  <ParameterRequired>Required</ParameterRequired>
                </ParameterHeader>
                <ParameterDescription>
                  Name of the user the upload belongs to.
                </ParameterDescription>
              </ParameterContainer>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>detailed</ParameterValue>
                  <ParameterType>boolean</ParameterType>
                  <ParameterRequired>Required</ParameterRequired>
                </ParameterHeader>
                <ParameterDescription>
                  Whether you want the analysis of the files uploaded to be
                  detailed or standard.
                </ParameterDescription>
              </ParameterContainer>
            </Parameters>
            <H3>Returns</H3>
            <ReturnsText>
              An object with a success value, a message, and a data object with
              the signed urls needed to upload your files to Google Cloud
              Services.
            </ReturnsText>
          </Column>
          <Column>
            <CodeBlock
              code={apiByLanguage.generateExtractURLs}
              type="code"
              setLanguage={handleSetLanguage}
              language={language}
              verb={"POST"}
              verbBackgroundColor={"#FC944A"}
              endpoint={"/api/generateUploadUrls"}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock
              code={apiByLanguage.generateExtractURLs.response}
              type="response"
            />
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Get Request Data</SectionHeader>
        <FlexRow>
          <Column>
            <Text>Get data for submitted data extraction request</Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>requestId</ParameterValue>
                  <ParameterType>query string</ParameterType>
                  <ParameterRequired>Required</ParameterRequired>
                </ParameterHeader>
              </ParameterContainer>
            </Parameters>
            <H3>Returns</H3>
            <ReturnsText>
              An object with a success value, a message and a data object with
              the request for the provided request ID given in the query string.
            </ReturnsText>
          </Column>
          <Column>
            <CodeBlock
              type="code"
              code={apiByLanguage.getRequestData}
              setLanguage={handleSetLanguage}
              language={language}
              verbBackgroundColor={"#1BDA1B"}
              verb={"GET"}
              endpoint={"/api/getRequestData?requestId=YOUR_REQUEST_ID"}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock
              code={apiByLanguage.getRequestData.response}
              type="response"
            />
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Get All Requests</SectionHeader>
        <FlexRow>
          <Column>
            <Text>
              Get overview data for all requests that are pending, declined, and
              approved.
            </Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterDescription>
                No parameters required.
              </ParameterDescription>
            </Parameters>
            <H3>Returns</H3>
            <ReturnsText>
              An object with a success value, a message and a data object with
              all the pending, declined and approved requests.
            </ReturnsText>
          </Column>
          <Column>
            <CodeBlock
              type={"code"}
              setLanguage={handleSetLanguage}
              language={language}
              verbBackgroundColor={"#1BDA1B"}
              verb={"GET"}
              endpoint={"/api/getAllRequests"}
              code={apiByLanguage.getAllRequests}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock
              code={apiByLanguage.getAllRequests.response}
              type="response"
            />
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Delete Request</SectionHeader>
        <FlexRow>
          <Column>
            <Text>Delete a specific data extraction request</Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>requestId</ParameterValue>
                  <ParameterType>path parameter</ParameterType>
                  <ParameterRequired>Required</ParameterRequired>
                </ParameterHeader>
              </ParameterContainer>
            </Parameters>
            <H3>Returns</H3>
            <ReturnsText>
              An object with a success value and a message regarding the
              deletion of the provided request Id.
            </ReturnsText>
          </Column>
          <Column>
            <CodeBlock
              code={apiByLanguage.deleteRequest}
              setLanguage={handleSetLanguage}
              language={language}
              type={"code"}
              verb={"DELETE"}
              verbBackgroundColor={"#d50000"}
              endpoint={"/api/deleteRequest/{requestId}"}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock
              code={apiByLanguage.deleteRequest.response}
              type="response"
            />
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Create Upload Form</SectionHeader>
        <FlexRow>
          <Column>
            <Text>
              Create a pdf bill upload form which can be sent to a customer or
              partner. Provide a name or id for the form, and the API will
              return a unique url to a whitelabelled upload form, which can be
              embedded or sent directly to an end user. Upload forms are
              reusable.
            </Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>customer</ParameterValue>
                  <ParameterType>string</ParameterType>
                </ParameterHeader>
                <ParameterDescription>
                  Pass the name of the customer you would send this form to as
                  mutlipart/form-data in the request body.
                </ParameterDescription>
              </ParameterContainer>
            </Parameters>
          </Column>
          <Column>
            <CodeBlock
              code={apiByLanguage.createUploadForm}
              verb={"POST"}
              verbBackgroundColor={"#FC944A"}
              endpoint={"/api/createForm"}
              language={language}
              setLanguage={handleSetLanguage}
              type={"code"}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock
              code={apiByLanguage.createUploadForm.response}
              type="response"
            />
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Test Webhook Endpoint</SectionHeader>
        <FlexRow>
          <Column>
            <Text>
              A GET endpoint to test the currently set endpoint. Sends a simple
              object to the endpoint saved in the account.
            </Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterDescription>
                No parameters required.
              </ParameterDescription>
            </Parameters>
            <H3>Returns</H3>
            <ReturnsText>
              Returns true if the webhook functionality is active on your
              account.
            </ReturnsText>
          </Column>
          <Column>
            <CodeBlock
              type={"code"}
              code={apiByLanguage.testWebhook}
              language={language}
              setLanguage={handleSetLanguage}
              verb={"GET"}
              verbBackgroundColor={"#1BDA1B"}
              endpoint={"/api/testWebhook"}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock
              code={apiByLanguage.testWebhook.response}
              type="response"
            />
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Get All PDFs</SectionHeader>
        <FlexRow>
          <Column>
            <Text>Get zip directory of all pdfs associated with request</Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>requestId</ParameterValue>
                  <ParameterType>path parameter</ParameterType>
                  <ParameterRequired>Required</ParameterRequired>
                </ParameterHeader>
                <ParameterDescription>
                  Example: dad4bce8-f5cb-4078-a211-995864315e39. The unique
                  request Id for the data extraction request. Found in the read
                  bills response and the webhook.
                </ParameterDescription>
              </ParameterContainer>
            </Parameters>
            <H3>Returns</H3>
            <ReturnsText>
              Returns a zip folder which includes all the PDF files that have
              been uploaded.
            </ReturnsText>
          </Column>
          <Column>
            <CodeBlock
              code={apiByLanguage.getAllPDFs}
              type={"code"}
              verb={"GET"}
              verbBackgroundColor={"#1BDA1B"}
              language={language}
              setLanguage={handleSetLanguage}
              endpoint={"/api/getPdfs/{requestId}"}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock
              code={apiByLanguage.getAllPDFs.response}
              type="response"
            />
          </Column>
        </FlexRow>
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>Get Single PDF</SectionHeader>
        <FlexRow>
          <Column>
            <Text>Get a specific pdf from a request</Text>
            <H3>Parameters</H3>
            <Parameters>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>requestId</ParameterValue>
                  <ParameterType>string {`<uuid>`}</ParameterType>
                </ParameterHeader>
                <ParameterDescription>
                  The unique request Id for the data extraction request. Found
                  in the read bills response and the webhook.
                </ParameterDescription>
              </ParameterContainer>
              <ParameterContainer>
                <ParameterHeader>
                  <ParameterValue>filename</ParameterValue>
                  <ParameterType>string</ParameterType>
                </ParameterHeader>
                <ParameterDescription>
                  Name of the file you would like returned as a PDF
                </ParameterDescription>
              </ParameterContainer>
            </Parameters>
          </Column>
          <Column>
            <CodeBlock
              code={apiByLanguage.getPDF}
              type={"code"}
              verb={"POST"}
              verbBackgroundColor={"#FC944A"}
              endpoint={"/api/getFile"}
              language={language}
              setLanguage={handleSetLanguage}
            />
            <SubSectionHeader>Response</SubSectionHeader>
            <CodeBlock code={apiByLanguage.getPDF.response} type="response" />
          </Column>
        </FlexRow>
      </SectionContainer>
    </Container>
  );
};

export default APIOverview;
