import React, { useState } from "react";
import { APIContainer, Header } from "../styles/api.js";
import { Container } from "../styles/landingPage";
import Tabs from "../components/tabs.js";
import NavBar from "../components/navBar.js";
import APIOverview from "../components/apiOverview.js";
import APIStepByStep from "../components/apiStepByStep.js";

const API = () => {
  const [activeTab, setActiveTab] = useState(0);
  const setActiveTabInParent = (value) => {
    setActiveTab(value);
  };
  return (
    <Container>
      <NavBar />
      <APIContainer>
        <Tabs
          tabs={["Overview", "Step by step guide"]}
          setInParent={setActiveTabInParent}
        />
        {activeTab === 0 && <APIOverview />}
        {activeTab === 1 && <APIStepByStep />}
      </APIContainer>
    </Container>
  );
};

export default API;
