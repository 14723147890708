import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  // @media only screen and (max-width: 950px) {
  //   display: none;
  // }
  @media only screen and (max-width: 430px) {
    margin-top: 80px;
  }
`;

export const Image = styled.img`
  padding: 0 200px;
  margin-top: 100px;
  @media only screen and (max-width: 430px) {
    display: none;
  }
`;

export const MobileImage = styled.img`
  display: none;
  @media only screen and (max-width: 430px) {
    display: block;
    padding: 20px;
    margin-top: 55px;
  }
`;
