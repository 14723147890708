import React, { useState } from "react";
import {
  Container,
  LogoSVG,
  NavMenu,
  NavMenuImage,
  NavMenuContainer,
  BlueCross,
  NavMenuItem,
  Link,
} from "../styles/navBar";
import Logo from "../assets/Logo.svg";
import NavItems from "./navItems";
import NavActions from "./navActions";
import NavMenuImg from "../assets/NavMenu.svg";
import BlueX from "../assets/BlueX.svg";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const onClickNavMenu = () => {
    setMobileNavOpen(true);
  };
  const closeNavMenu = (value) => {
    setMobileNavOpen(false);
    navigate(`/${value}`);
  };
  return (
    <Container>
      <LogoSVG src={Logo} />
      <NavItems />
      <NavActions />
      <NavMenu>
        <NavMenuImage src={NavMenuImg} onClick={onClickNavMenu} />
      </NavMenu>
      {mobileNavOpen && (
        <NavMenuContainer>
          <BlueCross src={BlueX} onClick={closeNavMenu} />
          <NavMenuItem>
            <Link href="#home" onClick={() => closeNavMenu("#home")}>
              Home
            </Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link
              href="#howItWorks"
              onClick={() => closeNavMenu("#howItWorks")}
            >
              How it works
            </Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link href="#api" onClick={() => closeNavMenu("#api")}>
              API
            </Link>
          </NavMenuItem>
          <NavMenuItem>
            <Link href="/pricing" onClick={closeNavMenu}>
              Pricing
            </Link>
          </NavMenuItem>
        </NavMenuContainer>
      )}
    </Container>
  );
};

export default NavBar;
