import styled from "styled-components";

export const CodeBlockClass = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const Endpoint = styled.div`
  font-size: 14px;
`;
