import styled from "styled-components";

export const APIContainer = styled.div`
  padding: 48px;
  font-family: "Plus Jakarta Sans";
`;

export const Header = styled.div`
  font-size: 36px;
  font-weight: 600;
`;
