import React, { useState } from "react";
import {
  Container,
  ListItem,
  Header,
  Icon,
  Title,
  Content,
  MobileContent,
} from "../styles/contentList";
import { isNil } from "lodash";

const ContentList = ({ items, setImageFromChild }) => {
  const [active, setActive] = useState(0);
  const handleItemClick = (index) => {
    setActive(index);
    setImageFromChild(index);
  };
  return (
    <Container>
      {items.map((item, i) => {
        return (
          <ListItem
            active={active === i ? true : false}
            onClick={() => handleItemClick(i)}
          >
            <Header>
              {!isNil(item.icon) && <Icon>{item.icon}</Icon>}
              <Title active={active === i ? true : false}>{item.title}</Title>
            </Header>
            <Content
              active={active === i ? true : false}
              iconExists={!isNil(item.icon)}
            >
              {item.description}
            </Content>
          </ListItem>
        );
      })}
    </Container>
  );
};

export default ContentList;
