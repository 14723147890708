import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
`;

export const ListItem = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  gap: 5px;
  width: 100%;
`;

export const Icon = styled.div``;

export const Title = styled.div`
  font-size: 12px;
`;
