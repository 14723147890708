import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import { CopyButtonStyled } from "../styles/copyButton.js";

const CopyButton = ({ code }) => {
  return (
    <CopyButtonStyled>
      <CopyToClipboard text={code} onCopy={() => alert("Copied!")}>
        <div>
          <FaRegCopy />
        </div>
      </CopyToClipboard>
    </CopyButtonStyled>
  );
};

export default CopyButton;
