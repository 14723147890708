import React from "react";
import {
  Container,
  Verb,
  Endpoint,
  TrueContainer,
} from "../styles/inlineCodeBlock";

const InlineCodeBlock = ({ verb, backgroundColor, endpoint }) => {
  return (
    <TrueContainer>
      <Container>
        <Verb backgroundColor={backgroundColor}>{verb}</Verb>
        <Endpoint>{endpoint}</Endpoint>
      </Container>
    </TrueContainer>
  );
};

export default InlineCodeBlock;
