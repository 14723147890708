import styled from "styled-components";
import { blue } from "../../utils/colors.js";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Option = styled.div`
  margin: 0 15px;
  color: ${(props) => (props.selected ? blue : "#B5B5B5")};
  font-weight: 600;
  font-size: 16px;
`;
