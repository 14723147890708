import React from "react";
import {
  Container,
  ContentConatiner,
  Email,
  Image,
  MobileImage,
} from "../styles/footer";
import UpwardLogoWhite from "../assets/UpwardLogoWhite.svg";
import LogoMarkWhite from "../assets/LogoMarkWhite.svg";

const Footer = () => {
  return (
    <Container>
      <ContentConatiner>
        <Email>support@buildupward.com</Email>
        <Image src={UpwardLogoWhite} />
        <MobileImage src={LogoMarkWhite} />
      </ContentConatiner>
    </Container>
  );
};

export default Footer;
