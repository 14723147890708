import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  @media only screen and (max-width: 950px) {
    flex-direction: column;
    align-items: unset;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: start;
  width: 46%;
  padding-left: 50px;
  @media only screen and (max-width: 1150px) {
    margin-top: 100px;
  }

  @media only screen and (max-width: 950px) {
    width: 90%;
  }
  @media only screen and (max-width: 430px) {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }
`;

export const HeroText = styled.div`
  font-size: 55px;
  line-height: 75px;
  font-weight: 700;
  @media only screen and (max-width: 430px) {
    font-size: 25px;
    line-height: 37px;
    font-weight: 600;
    width: 100%;
  }
`;

export const HeroSubText = styled.div`
  font-size: 24px;
  line-height: 36px;
  @media only screen and (max-width: 430px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ImageContainer = styled.div`
  width: 54%;
  display: flex;
  justify-content: end;
  @media only screen and (max-width: 950px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const ImageFull = styled.img`
  width: 100%;
  @media only screen and (min-width: 951px) {
    display: none;
  }
`;
