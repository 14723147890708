import LandingPage from "../pages/landingPage.js";
import Pricing from "../pages/pricing.js";
import API from "../pages/api.js";
const routes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/api",
    element: <API />,
  },
];

export default routes;
