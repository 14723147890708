import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  atomDark,
  prism,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  CodeBlockClass,
  Header,
  Endpoint,
} from "../styles/syntaxHighlighter.js";
import CopyButton from "./copyButton.js";
import Verb from "./verb.js";
import { isNil } from "lodash";
import TryNowButton from "./tryNowButton.js";

const CodeBlock = ({
  code,
  type,
  setLanguage,
  language,
  verb = null,
  verbBackgroundColor,
  endpoint = null,
  styleType = null,
}) => {
  return (
    <div>
      {type === "code" && (
        <Header>
          <select value={language} onChange={setLanguage}>
            <option value="cURL">cURL</option>
            <option value="javascript">JavaScript</option>
            <option value="python">Python</option>
          </select>
          {!isNil(verb) && (
            <Verb verb={verb} backgroundColor={verbBackgroundColor} />
          )}
          {!isNil(endpoint) && <Endpoint>{endpoint}</Endpoint>}
        </Header>
      )}

      {type === "code" && (
        <CodeBlockClass>
          <CopyButton code={code[language]} />
          <SyntaxHighlighter
            language={language}
            style={!isNil(styleType) ? prism : atomDark}
            showLineNumbers
          >
            {code[language]}
          </SyntaxHighlighter>
        </CodeBlockClass>
      )}
      {type === "response" && (
        <CodeBlockClass>
          <CopyButton code={code} />
          <SyntaxHighlighter
            style={!isNil(styleType) ? prism : docco}
            language="json"
          >
            {code}
          </SyntaxHighlighter>
        </CodeBlockClass>
      )}
    </div>
  );
};

export default CodeBlock;
