import React, { useState } from "react";
import {
  Container,
  ContentContainer,
  Icon,
  Headline,
  Header,
  Image,
  ListContainer,
  ImageContainer,
  MobileContent,
  MobileTitle,
} from "../styles/howItWorks";
import { SubHeader } from "../styles/common";
import ContentList from "./contentList";
import GetBills from "../assets/GetBills.svg";
import USA from "../assets/USA.svg";
import ReviewData from "../assets/ReviewData.svg";
import ShareData from "../assets/ShareData.svg";

const HowItWorks = () => {
  const [whichImage, setWhichImage] = useState(0);
  const setImageFromChild = (index) => {
    setWhichImage(index);
  };
  const images = [GetBills, ReviewData, ShareData];
  const items = [
    {
      icon: <Icon>01</Icon>,
      title: "Upload bills",
      description:
        "Simply drag and drop your bills to get started. Or send an upload link directly  to your customers and skip emailing back and forth for PDFs.",
    },
    {
      icon: <Icon>02</Icon>,
      title: "Analyze data",
      description:
        "Get detailed insights into usage, costs and billing, demand, sustainability and more.",
    },
    {
      icon: <Icon>03</Icon>,
      title: "Share and export",
      description:
        "Collaborate with your team directly in the upward platform. Export data in multiple formats to easily integrate with your current process.",
    },
  ];
  return (
    <a name="howItWorks">
      <Container>
        <Headline>How it works</Headline>

        <Header>Three simple steps</Header>
        <SubHeader>
          Processing utility bills has never been easier with Upward. Request
          bills directly from customers, extract key insights, and collaborate
          with your team, all in one place.
        </SubHeader>
        <ContentContainer>
          <ListContainer>
            <ContentList items={items} setImageFromChild={setImageFromChild} />
          </ListContainer>
          <MobileTitle>{items[whichImage].title}</MobileTitle>
          <MobileContent>{items[whichImage].description}</MobileContent>
          <ImageContainer>
            <Image src={images[whichImage]} />
          </ImageContainer>
        </ContentContainer>
      </Container>
    </a>
  );
};

export default HowItWorks;
