import styled from "styled-components";

export const Container = styled.div`
  display: none;
  @media only screen and (max-width: 430px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    margin-top: 70px;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

export const Image = styled.img`
  width: 100%;
  max-height: 350px;
  //   padding-left: 50px;
  max-width: 600px;
`;

export const ImageTextContainer = styled.div`
  background-color: #eaedff;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 70px;
`;

export const Title = styled.div`
  color: #3958ff;
  font-size: 20px;
  text-align: left;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 21px;
`;
