import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const StyledTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-bottom: ${(props) =>
    props.active ? "2px solid #000000" : "2px solid transparent"};
  color: ${(props) => (props.active ? "#000000" : "rgba(0,0,0,0.5)")};
  font-size: 16px;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  cursor: pointer;
`;
