import styled from "styled-components";
import { blue } from "../utils/colors";

export const Container = styled.div`
  margin-top: 200px;
  // @media only screen and (max-width: 950px) {
  //   display: none;
  // }
  @media only screen and (max-width: 430px) {
    margin-top: 80px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 150px;
  margin-top: 100px;
  align-items: center;
  @media only screen and (max-width: 430px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 50px;
  }
`;

export const Icon = styled.div`
  color: ${blue};
  font-size: 35px;
  @media only screen and (max-width: 430px) {
    font-size: 12px;
  }
`;

export const Headline = styled.div`
  color: ${blue};
  font-size: 20px;
  margin: 0 auto 80px auto;
  width: max-content;
  @media only screen and (max-width: 430px) {
    font-size: 14px;
    margin-bottom: 50px;
  }
`;

export const Header = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto 30px auto;
  width: max-content;
  @media only screen and (max-width: 430px) {
    font-size: 16px;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const ListContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: 430px) {
    width: 100%;
    display: flex;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  padding-right: 50px;
  @media only screen and (max-width: 430px) {
    width: 100%;
    padding: 0px 20px;
  }
`;

export const MobileContent = styled.div`
  display: none;
  @media only screen and (max-width: 430px) {
    display: block;
    font-size: 14px;
    // margin-top: 16px;
    padding: 0px 20px 0px 20px;
  }
`;

export const MobileTitle = styled.div`
  display: none;
  @media only screen and (max-width: 430px) {
    display: flex;
    justify-content: start;
    font-size: 20px;
    margin-top: 16px;
    padding: 0px 20px 0px 20px;
    text-align: left;
    width: 100%;
  }
`;
