import React from "react";
import { Container } from "../styles/landingPage";
import NavBar from "../components/navBar";
import Hero from "../components/hero";
import Introducing from "../components/introducing";
import HowItWorks from "../components/howItWorks";
import Features from "../components/features";
import ExtraFeatures from "../components/extraFeatures";
import Contact from "../components/contact";
import Footer from "../components/footer";
import MobileHowItWorks from "../components/mobile/howItWorks";
import HowWeCompare from "../components/howWeCompare.js";
import MobileFeatures from "../components/mobile/features";

const LandingPage = () => {
  return (
    <Container>
      <NavBar />
      <Hero />
      <Introducing />
      <HowItWorks />

      <Features />
      <MobileFeatures />
      <ExtraFeatures />
      <HowWeCompare />
      <Contact />
      <Footer />
    </Container>
  );
};

export default LandingPage;
