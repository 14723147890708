import React, { useState, useEffect } from "react";
import { Container, Item, Link } from "../styles/navItems";
import { useNavigate } from "react-router-dom";

const NavItems = () => {
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    var path = window.location.pathname;
    var hash = window.location.hash;
    console.log(path, hash);
    if (path === "/api") {
      setActive(4);
    }
    if (path === "/pricing") {
      setActive(3);
    }
    if (hash === "#home") {
      setActive(0);
    } else if (hash === "#howItWorks") {
      setActive(1);
    } else if (hash === "#api") {
      setActive(2);
    }
  }, [window.location]);

  const onClickItem = (index) => {
    if (index < 3) {
      setActive(index);
      navigate("/");
    }
  };
  return (
    <Container>
      <Link href="#home" onClick={() => onClickItem(0)}>
        <Item active={active === 0 ? true : false}>Home</Item>
      </Link>
      <Link href="#howItWorks" onClick={() => onClickItem(1)}>
        <Item active={active === 1 ? true : false}>How it works</Item>
      </Link>
      <Link href="#api" onClick={() => onClickItem(2)}>
        <Item active={active === 2 ? true : false}>API</Item>
      </Link>
      <Link href="/pricing" onClick={() => onClickItem(3)}>
        <Item active={active === 3 ? true : false}>Pricing</Item>
      </Link>
      <Link href="/api" onClick={() => onClickItem(3)}>
        <Item active={active === 4 ? true : false}>Docs</Item>
      </Link>
    </Container>
  );
};

export default NavItems;
