import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  padding: 20px 80px;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
`;

export const LogoSVG = styled.img``;

export const NavMenu = styled.div`
  display: none;
  @media only screen and (max-width: 430px) {
    display: block;
  }
`;

export const NavMenuImage = styled.img``;

export const NavMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.15);
`;

export const BlueCross = styled.img``;

export const NavMenuItem = styled.div`
  font-size: 26px;
  color: #9e9e9e;
  margin: 10px 0px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: #9e9e9e;
`;
