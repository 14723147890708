import React from "react";
import {
  Container,
  TextContainer,
  HeroText,
  HeroSubText,
  ImageContainer,
  Image,
  ImageFull,
} from "../styles/hero";
import PrimaryButton from "./button";
import HeroImage from "../assets/HeroImage.svg";
import HeroImageFull from "../assets/MobileHeroImage.svg";
import { Link } from "../styles/navItems";

const Hero = () => {
  return (
    <a name="home">
      <Container>
        <TextContainer>
          <div>
            <HeroText>Unlock Energy Insights.</HeroText>
            <HeroText>Unleash Productivity.</HeroText>
          </div>

          <HeroSubText>
            Extract data from energy and water bills automatically with AI
            powered OCR technology. Get the utility data and insights you need
            faster and more accurately with Upward.
          </HeroSubText>
          <Link href="https://calendly.com/max-s1ha/30min" target="_blank">
            <PrimaryButton text={"Book a live demo"} />
          </Link>
        </TextContainer>
        <ImageContainer>
          <Image src={HeroImage} />
          <ImageFull src={HeroImageFull} />
        </ImageContainer>
      </Container>
    </a>
  );
};

export default Hero;
