import React, { useState } from "react";
import { Container, Option } from "./SameColorToggleStyles.js";
import "./SameColorToggle.css";

const SameColorToggle = ({ option1, option2, setPeriod }) => {
  const [optionSelected, setOptionSelected] = useState(0);
  const clickToggle = () => {
    if (optionSelected === 0) {
      setOptionSelected(1);
      setPeriod("monthly");
    } else {
      setOptionSelected(0);
      setPeriod("annual");
    }
  };

  return (
    <Container>
      <Option selected={optionSelected === 0}>{option1}</Option>
      <label class="switch">
        <input type="checkbox" onClick={clickToggle} />
        <div>
          <span></span>
        </div>
      </label>
      <Option selected={optionSelected === 1}>{option2}</Option>
    </Container>
  );
};

export default SameColorToggle;
