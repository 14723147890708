import styled from "styled-components";
import { blue } from "../utils/colors";

export const Headline = styled.div`
  color: ${blue};
  font-size: 20px;
  margin: 0 auto;
  margin-bottom: 80px;
  @media only screen and (max-width: 430px) {
    font-size: 14px;
    margin-bottom: 50px;
  }
`;

export const Header = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 30px;
  @media only screen and (max-width: 430px) {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
    // padding: 20px;
  }
`;

export const SubHeader = styled.div`
  font-size: 20px;
  margin: 0 auto;
  line-height: 24px;
  max-width: 800px;
  text-align: center;
  @media only screen and (max-width: 430px) {
    font-size: 13px;
    line-height: 18px;
    padding: 0 20px;
  }
`;
