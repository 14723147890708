import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 30px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SectionHeader = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const Column = styled.div`
  width: 50%;
`;

export const SubSectionHeader = styled.div`
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Text = styled.div`
  color: #353a44;
  font-size: 14px;
  max-width: 600px;
  font-weight: 500;
  line-height: 21px;
`;

export const H3 = styled.h3`
  font-weight: 600;
`;

export const ParameterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ParameterHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ParameterValue = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const ParameterType = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #596171;
`;

export const ParameterDescription = styled.div`
  font-size: 14px;
  max-width: 600px;
`;

export const ParameterRequired = styled.div`
  font-size: 12px;
  color: #d50000;
`;

export const Parameters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ReturnsText = styled.div`
  font-size: 14px;
  max-width: 600px;
`;
