import styled from "styled-components";
import { dark_blue, white } from "../utils/colors";

export const Container = styled.div`
  background-color: ${dark_blue};
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 300px;
  @media only screen and (max-width: 430px) {
    padding: 20px;
    gap: 80px;
  }
`;

export const TextImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  @media only screen and (max-width: 430px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const Image = styled.img`
  width: 50%;
  @media only screen and (max-width: 430px) {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media only screen and (max-width: 430px) {
    gap: 25px;
  }
`;

export const Title = styled.div`
  color: ${white};
  font-size: 48px;
  @media only screen and (max-width: 430px) {
    font-size: 20px;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  color: #eaedff;
  font-size: 20px;
  line-height: 30px;
  @media only screen and (max-width: 430px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const StyledA = styled.a`
  @media only screen and (max-width: 430px) {
    display: none;
  }
`;

export const MobileStyledA = styled.a`
  display: none;
  @media only screen and (max-width: 430px) {
    display: flex;
    flex-direction: column;
  }
`;
