import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto auto;
  height: 500px;
  align-items: center;
  gap: 50px;
  padding-top: 10%;
  box-sizing: border-box;
  @media only screen and (max-width: 430px) {
    padding-top: 100px;
    height: 300px;
  }
`;

export const Headline = styled.div`
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  @media only screen and (max-width: 430px) {
    font-size: 30px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 35px;
`;

export const HideOnMobile = styled.div`
  @media only screen and (max-width: 430px) {
    display: none;
  }
`;
