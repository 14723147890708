import styled from "styled-components";

export const TrueContainer = styled.span`
  display: inline-block;
  margin: 0 5px;
`;

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  background-color: #f1f1f1;
  display: flex;
  gap: 8px;
  padding: 0px 8px;
  width: max-content;
  align-items: center;
`;

export const Verb = styled.div`
  color: ${(props) => props.backgroundColor};
  font-weight: 800;
  font-size: 12px;
`;

export const Endpoint = styled.div`
  font-size: 12px;
`;
