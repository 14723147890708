import React, { useState } from "react";
import {
  Container,
  ListContainer,
  ImageContainer,
  Image,
  ImageTextContainer,
  Title,
  Description,
} from "./styles/features";
import ContentList from "../contentList";
import USA from "../../assets/USA.svg";
import EnergyTypes from "../../assets/EnergyTypes.svg";
import Batch from "../../assets/Batch.svg";

const MobileFeatures = () => {
  const [whichImage, setWhichImage] = useState(0);
  const setImageFromChild = (index) => {
    setWhichImage(index);
  };
  const images = [USA, EnergyTypes, Batch];
  const items = [
    {
      title: "Universal utility support",
      description:
        "Upward supports bills from any utility or provider. This compatibility ensures consistency across geographies and regions.",
    },
    {
      title: "Supports all energy types",
      description:
        "From electricity and water to natural gas and fuel oil, Upward is equipped to accurately extract data from any type of bill.",
    },
    {
      title: "Multiple Bills",
      description:
        "Our system handles bulk document uploads as well as documents containing multiple bills, eliminating the work to sort and upload individual files.",
    },
  ];
  return (
    <Container>
      <ListContainer>
        <ContentList items={items} setImageFromChild={setImageFromChild} />
      </ListContainer>
      <ImageTextContainer>
        <Title>{items[whichImage].title}</Title>
        <Description>{items[whichImage].description}</Description>
        <ImageContainer>
          <Image src={images[whichImage]} />
        </ImageContainer>
      </ImageTextContainer>
    </Container>
  );
};

export default MobileFeatures;
