import styled from "styled-components";
import { blue, light_blue, hover_light_blue } from "../utils/colors";

export const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const Secondary = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-bottom: 2px solid #ffffff;
  padding-bottom: 5px;
  font-weight: 600;
  &:hover {
    font-weight: 600;
    color: ${blue};
    cursor: pointer;
    padding-bottom: 0px;
    border-bottom: 0px;
    &:after {
      content: "";
      // This is necessary for the pseudo element to work.
      display: block;
      // This will put the pseudo element on its own line.
      margin: 0 0;
      // This will center the border.
      width: 70%;
      // Change this to whatever width you want.
      padding-bottom: 5px;
      // This creates some space between the element and the border.
      border-bottom: 2px solid ${blue};
      // This creates the border. Replace black with whatever color you want.
    }
  }
`;

export const Primary = styled.div`
  display: flex;
  align-items: center;
  background-color: ${light_blue};
  border: 2px solid ${blue};
  border-radius: 50px;
  padding: 10px ${(props) => (props.sides ? props.sides : "35px")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  color: ${blue};
  font-weight: 600;
  &:hover {
    background-color: ${hover_light_blue};
    cursor: pointer;
  }
`;
