import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./pages/landingPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes/routesConfig";

const router = createBrowserRouter(routes);

function App() {
  return (
    <div className="container">
      <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      {/* <LandingPage /> */}
    </div>
  );
}

export default App;
