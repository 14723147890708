import React, { useState } from "react";
import { Container, StyledTab } from "../styles/tabs.js";

const Tabs = ({ tabs, setInParent }) => {
  const [active, setActive] = useState(0);
  const onClickTab = (index) => {
    setActive(index);
    setInParent(index);
  };
  return (
    <Container>
      {tabs.map((tab, i) => {
        return (
          <StyledTab active={i === active} onClick={() => onClickTab(i)}>
            {tab}
          </StyledTab>
        );
      })}
    </Container>
  );
};

export default Tabs;
