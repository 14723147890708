import React from "react";
import HowItWorks from "./howItWorks";
import {
  Container,
  Header,
  CompareContainer,
  UpwardCard,
  Logo,
  LogoContainer,
  FeaturesContainer,
  Feature,
  Check,
  FeatureText,
  OthersContainer,
  OthersHeader,
  OthersHeaderText,
  OthersHeaderTextContainer,
  HowWeCompareImageContainer,
} from "../styles/howWeCompare.js";
import BlackLogo from "../assets/FullUpwardLogoBlack.svg";
import FeatureGreenCheck from "../assets/FeatureGreenCheck.svg";
import HowWeCompareImage from "../assets/HowWeCompareImage.svg";

const HowWeCompare = () => {
  return (
    <Container>
      <Header>How We Compare</Header>
      <HowWeCompareImageContainer src={HowWeCompareImage} />
      {/* <CompareContainer>
        <UpwardCard>
          <LogoContainer>
            <Logo src={BlackLogo} />
          </LogoContainer>
          <FeaturesContainer>
            <Feature>
              <Check src={FeatureGreenCheck} />
              <FeatureText>Universal compatability</FeatureText>
            </Feature>
            <Feature>
              <Check src={FeatureGreenCheck} />
              <FeatureText>Detailed data extraction</FeatureText>
            </Feature>
            <Feature>
              <Check src={FeatureGreenCheck} />
              <FeatureText>Standardized output</FeatureText>
            </Feature>
            <Feature>
              <Check src={FeatureGreenCheck} />
              <FeatureText>No setup required</FeatureText>
            </Feature>
            <Feature>
              <Check src={FeatureGreenCheck} />
              <FeatureText>Large files</FeatureText>
            </Feature>
            <Feature>
              <Check src={FeatureGreenCheck} />
              <FeatureText>Multiple energy types</FeatureText>
            </Feature>
            <Feature>
              <Check src={FeatureGreenCheck} />
              <FeatureText>Multiple bills per file</FeatureText>
            </Feature>
          </FeaturesContainer>
        </UpwardCard>
        <OthersContainer>
          <OthersHeader>
            <OthersHeaderTextContainer>
              <OthersHeaderText>Other utility bill extractors</OthersHeaderText>
              <OthersHeaderText>Catch all data extractors</OthersHeaderText>
            </OthersHeaderTextContainer>
          </OthersHeader>
        </OthersContainer>
      </CompareContainer> */}
    </Container>
  );
};

export default HowWeCompare;
