import styled from "styled-components";
import { blue, black } from "../utils/colors";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`;

export const Item = styled.div`
  font-size: 16px;
  color: ${(props) => (props.active ? blue : black)};
  cursor: pointer;
  ${(props) =>
    props.active &&
    `
  &:after {
    content: "";
    // This is necessary for the pseudo element to work.
    display: block;
    // This will put the pseudo element on its own line.
    margin: 0 0;
    // This will center the border.
    width: 70%;
    // Change this to whatever width you want.
    padding-bottom: 5px;
    // This creates some space between the element and the border.
    border-bottom: 2px solid ${blue};
    // This creates the border. Replace black with whatever color you want.
  }
  `};
  &:hover {
    color: ${blue};
    cursor: pointer;
    padding-bottom: 0px;
    border-bottom: 0px;
    &:after {
      content: "";
      // This is necessary for the pseudo element to work.
      display: block;
      // This will put the pseudo element on its own line.
      margin: 0 0;
      // This will center the border.
      width: 70%;
      // Change this to whatever width you want.
      padding-bottom: 5px;
      // This creates some space between the element and the border.
      border-bottom: 2px solid ${blue};
      // This creates the border. Replace black with whatever color you want.
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: #000000;
`;
